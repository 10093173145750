<template>
    <form 
        v-if="customerEdit"
        class="flex flex-col content-start h-full"
        @submit.prevent="updateCustomer()">
        
        <div class="flex-none bg-gray-200 flex items-center py-4 px-4">
            <button 
                class="button transparent mr-2"
                type="button"
                @click="$emit('close')">
                 
                <i class="fas fa-times " />
            </button>
            
            <h2 class="text-black m-0 flex-grow">{{$t('customers.editCustomer')}}</h2>
            
            <div 
                v-if="customerEdit.deletedAt"
                class="flex-grow text-left">
                
                {{$t('customers.customerWasDeleted')}}
                <span>{{ customerEdit.deletedAt | moment('from') }}</span>
            </div>
            
            <a 
                v-if="!customerEdit.deletedAt"
                :disabled="loading"
                class="button transparent link ml-2"
                :class="{'loading' : loading == 'delete'}"
                @click.prevent="deleteCustomer()">
                
                <i class="fas fa-trash mr-2" />
                <span class="">{{$t('general.delete')}}</span>
            </a>
            
            <button 
                :disabled="loading"
                type="submit"
                class="button submit ml-4"
                :class="{'loading' : loading == 'update'}">
                 
                <i class="fas fa-check mr-2" />
                {{ $t('general.save') }}
             </button>
        </div>
        
        <div class="flex-none bg-gray-200 px-6">
            <div class="flex">
                <div 
                    class="
                        cursor-pointer py-2 px-4
                        md:py-3
                        md:px-6
                    "
                    :class="section == 'details' ? 'bg-white text-black border-t-2 border-cyan' : 'hover:text-black'"
                    @click="section = 'details'">
                    
                    {{$t('customers.details')}}
                </div>
                <div 
                    class="
                        cursor-pointer py-2 px-4
                        md:py-3
                        md:px-6
                    "
                    :class="section == 'offers' ? 'bg-white text-black border-t-2 border-cyan' : 'hover:text-black'"
                    @click="section = 'offers'">
                    
                    {{$t('customers.offers')}}
                </div>
            </div>
        </div>
        
        <div class="flex-grow overflow-hidden flex w-full h-full ">
            <div 
                v-if="section == 'details'"
                class="
                    flex-grow overflow-auto 
                    px-8
                    pb-16
                ">
                
                <section v-if="customerEdit.isCompany">
                    <div class="grid grid-cols-1 md:grid-cols-2 md:gap-4">
                        <InputSingleline 
                            v-model="customerEdit.name"
                            :required="true"
                            :label="$t('customers.companyName')"
                            :name="'name'"
                            :showRequiredWarning="showRequiredWarning"
                            ref="autofocus"
                        />
                        
                        <InputSingleline 
                            v-model="customerEdit.organizationNumber"
                            :label="$t('customers.organizationNumber')"
                            :name="'organizationNumber'"
                            :placeholder="$t('customers.organizationNumberExample')"
                            :showRequiredWarning="showRequiredWarning"
                        />
                    </div>
                </section>
                <section>
                    <h2 class="mt-8 mb-6">
                        {{ $t('customers.contactPersons') }}
                    </h2>
                    
                    <div 
                        v-for="(contact,index) in customerEdit.contacts"
                        :key="index"
                        class="border rounded bg-gray-100 px-8 py-3 mb-8">
                    
                        <InputSingleline 
                            v-model="contact.name"
                            :label="$t('customers.name')"
                            :required="true"
                            :name="'contact_name_'+index"
                            :showRequiredWarning="showRequiredWarning"
                            class="w-full"
                        />
                        
                        <InputSingleline 
                            v-model="contact.email"
                            :label="$t('customers.email')"
                            :required="true"
                            :name="'contact_email_'+index"
                            :showRequiredWarning="showRequiredWarning"
                            type="email"
                        />
                        
                        <!-- <InputSingleline 
                            v-model="contact.phone"
                            :label="'Phone Number'"
                            placeholder="+47 11 22 33 44"
                            :name="'contact_phone_'+index"
                            :showRequiredWarning="showRequiredWarning"
                            class="flex-1 ml-2"
                        /> -->
                        <!-- 
                        <div class="">
                            <pre>{{contact.phone}}</pre>
                        </div>
                        -->
                        
                        <div>
                            <label>{{$t('customers.phoneNumber')}}</label>
                            
                            <div class="flex items-start">
                                <input 
                                    type="text"
                                    placeholder=""
                                    v-model="contact.phone"
                                />
                                <a 
                                    class="button submit flex-none ml-2" 
                                    :href="'tel:'+contact.phone" 
                                    v-if="contact.phone">
                                    
                                    <i class="fas fa-phone text-xl" />
                                </a>
                            </div>

                            
                            <!-- <VueTelInput
                                v-model="contact.phone"
                                placeholder=""
                                :enabledCountryCode="true"
                                :validCharactersOnly="true"
                                @validate="contactPhoneValidate($event, index, contact)"
                            /> -->
                        </div>
                        
                    
                        <InputSingleline 
                            v-if="customerEdit.isCompany"
                            v-model="contact.title"
                            :label="$t('customers.jobTitle')"
                            :name="'contact_title_'+index"
                            :showRequiredWarning="showRequiredWarning"
                        />
                        
                        <div v-if="customerEdit.contacts.length > 1">
                            <label 
                                v-if="customerEdit.isCompany"
                                :for="'contact_isPrimary_'+index">
                                
                                {{$t('customers.contactRole')}}
                            </label>
                            
                            <label class="h-10 flex items-center pb-1">
                                <input 
                                    type="radio"
                                    @input="isPrimaryChange(contact)"
                                    :checked="contact.isPrimary"
                                    :id="'contact_isPrimary_'+index"
                                    class="mr-2"
                                />
                                
                                {{$t('customers.primaryContact')}}
                            </label>
                        </div>
                        
                        <a 
                            class="inline-block my-4 link"
                            @click="removeContact(index)">
                            
                            {{$t('customers.removeContact')}}
                        </a>
                    </div>
                    
                    <a 
                        class="link" 
                        @click="addContact">
                        
                        {{$t('customers.addNewContact')}}
                    </a>
                </section>
                <section>
                    <h2 class=" mb-0 mt-16">{{$t('customers.address')}}</h2>
                        
                    <InputSingleline 
                        v-model="customerEdit.street"
                        :label="$t('customers.street')"
                        :name="'street'"
                        :showRequiredWarning="showRequiredWarning"
                    />
                    
                    <div class="flex">
                        <InputSingleline 
                            v-model="customerEdit.postcode"
                            :label="$t('customers.postcode')"
                            :name="'postcode'"
                            :showRequiredWarning="showRequiredWarning"
                            class="flex-none w-32 mr-4"
                        />
                        
                        <InputSingleline 
                            v-model="customerEdit.city"
                            :label="$t('customers.city')"
                            :name="'city'"
                            :showRequiredWarning="showRequiredWarning"
                            class="flex-1"
                        />
                        
                    </div>
                    
                    <InputSingleline 
                        v-model="customerEdit.country"
                        :label="$t('customers.country')"
                        :name="'country'"
                        :showRequiredWarning="showRequiredWarning"
                    />
                </section>
                <section>
                    <h2 class=" mb-0 mt-16">{{$t('customers.contactInfo')}}</h2>
                    
                    <div class="flex">
                        <InputSingleline 
                            v-model="customerEdit.website"
                            :label="$t('customers.website')"
                            :name="'website'"
                            :showRequiredWarning="showRequiredWarning"
                            class="flex-1 mr-2"
                        />
                        
                        <InputSingleline 
                            v-model="customerEdit.fax"
                            :label="$t('customers.fax')"
                            :name="'fax'"
                            :showRequiredWarning="showRequiredWarning"
                            class="flex-1 ml-2"
                        />
                    </div>
                </section>
                <section>
                    <h2 class=" mb-0 mt-16">{{$t('customers.general')}}</h2>
                    
                    <div v-if="customerEdit.isCompany">
                        <label>
                            {{$t('customers.logo')}}
                        </label>
                        
                        <div
                            v-if="logo"
                            class="flex mb-4 h-32 ">
                            
                            <img 
                                :src="logo"
                                alt="Logo"
                                class="flex-grow border p-4 object-contain mr-4 h-32 "
                            />
                            
                            <div class="flex-none  text-right flex flex-col">
                                <a 
                                    :href="logo"
                                    target="_blank"
                                    download>
                                    
                                    <i 
                                        class="button fas fa-download text-xl mb-4" 
                                        :title="$t('general.download')"
                                    />
                                </a>
                                <i 
                                    class="button fas fa-trash text-xl mt-4"
                                    :title="$t('general.remove')"
                                    @click="customerEdit.logo = null"
                                />
                            </div>
                        </div>
                        
                        <InputUpload
                            v-if="!logo"
                            v-model="customerEdit.logo"
                            :name="'logo'"
                            :showRequiredWarning="showRequiredWarning"
                            :multiple="false"
                        />
                    </div>
                    
                    <InputMultiline 
                        v-model="customerEdit.notes"
                        :label="$t('customers.notes')"
                        :name="'notes'"
                        :showRequiredWarning="showRequiredWarning"
                    />
                </section>
                <label>
                    <input 
                        type="checkbox"
                        v-model="customerEdit.isCompany"
                        class="mr-2"
                    />
                    <span class="">{{$t('customers.customerIsCompany')}}</span>
                </label>
            </div>
            <div 
                v-else-if="section == 'offers'"
                class="
                    flex-grow overflow-auto 
                    px-8
                    pb-16
                    bg-gray-100
                    h-full
                ">
                
                <transition-group 
                    appear
                    name="slide-up" 
                    tag="div"
                    class=""
                    v-if="offersFiltered.length > 0">
                    
                    <OfferCard 
                        v-for="(offer) in offersFiltered"
                        :key="offer.offerId"
                        :offer="offer"
                        class="slide-up-item my-8 shadow rounded-lg"
                        @view="viewOfferLink(offer)"
                    />
                </transition-group>
                    
            </div>
        </div>
    </form>
</template>



<script>
// import sections from '@/data/CustomerFields.js';
// import { VueTelInput } from 'vue-tel-input';

export default {
    props: {
        customer: {
            type: Object,
            required: true,
        }
    },
    
    components: {
        'OfferCard': () => import('@/components/offers/OfferCard.vue'),
        // VueTelInput,
        // 'InputSingleline': () => import('@/components/input/InputSingleline.vue'),
        // 'InputMultiline': () => import('@/components/input/InputMultiline.vue'),
        // 'InputCheckboxList': () => import('@/components/input/InputCheckboxList.vue'),
        // 'InputRadioList': () => import('@/components/input/InputRadioList.vue'),
        // 'InputUpload': () => import('@/components/input/InputUpload.vue'),
    },
    
    data() {
        return {
            showRequiredWarning: false,
            loading: null,
            customerEdit: null,
            section: 'details',
        }
    },
    
    computed: {
        offersFiltered(){
            const offers = this.$store.getters.getOffers;
            
            if (offers) {
                return offers.filter( o => o.customerId == this.customer.customerId );
            }
            else {
                return []
            }
        },
        
        logo(){
            if (!this.customerEdit.logo) {
                return false;
            }
            return process.env.VUE_APP_FILES_ENDPOINT + '/'+this.accountId+'/' + this.customerEdit.logo.fileId +'.'+ this.customerEdit.logo.extension;
        }
    },
    
    watch:{
        customer(oldValue,newValue){
            if (oldValue.customerId !== newValue.customerId ) {
                this.createCustomerCopy();
            }
        }
    },
    
    methods: {
        contactPhoneValidate( event, index, contact){
            if (event.isValid) {
                contact.phone = event.number.international;
            }
        },
        
        isPrimaryChange( contact ){
            this.customerEdit.contacts.forEach( c => {
                c.isPrimary = false;
                // console.log('contact', c.name, c.isPrimary);
            });
            
            contact.isPrimary = true;
            // console.log('contact', contact);
            analytics.track( 'customerEdit isPrimaryChange', contact );
        },
        
        async updateCustomer() {
            try {
                this.loading = 'update';
                await this.$store.dispatch('updateCustomer', this.customerEdit);
                this.$notify({ type: 'success', text: this.$t('customers.savedCustomer') });
                this.$emit('close');
            } 
            catch (e) {
                this.$notify({ 
                    type: 'error', 
                    title: 'Error', 
                    text: this.$t('customers.problemSaving')
                });
                console.error(e);
            }
            finally {
                this.loading = null;
            }
        },
        
        async deleteCustomer() {
            try {
                this.loading = 'delete';
                await this.$store.dispatch('deleteCustomer', this.customerEdit);
                this.$notify({ type: 'success', text: this.$t('customers.deletedCustomer') });
                this.$emit('close');
            } 
            catch (e) {
                this.$notify({ 
                    type: 'error', 
                    title: 'Error', 
                    text: this.$t('customers.problemDeleting')
                });
                console.error(e);
            } 
            finally {
                this.loading = null;
            }
        },
        
        addContact(){
            const contactId = ('contact_'+Math.random()*10000).replace('.','');
            
            this.customerEdit.contacts.push({
                contactId
            });
            
            analytics.track( 'customerEdit addContact', {contactId} );
        },
        
        removeContact( index ){
            const contact = this.customerEdit.contacts[index];
            this.customerEdit.contacts.splice(index, 1);
            analytics.track( 'customerEdit removeContact', contact );
        },
        
        createCustomerCopy(){
            this.customerEdit = JSON.parse(
                JSON.stringify( 
                    Object.assign({
                        contacts: [],
                        ...this.customer,
                    })
                )
            );
        },
        
        closeHandler( e ){
            if (e.keyCode === 27) {
                this.$emit('close');
            }
        },
        
        viewOfferLink( offer ){
            this.$router.push({ name: 'offers-show', params: { offerId: offer.offerId } });
        },
        
    },
    
    mounted(){
        this.createCustomerCopy();
        
        window.addEventListener('keyup', this.closeHandler );
        analytics.track( 'customerEdit opened', this.customer);
    },
    
    beforeDestroy() {
        window.removeEventListener('keyup', this.closeHandler);
    },
    
    
}
</script>

<style lang="scss">
</style>
